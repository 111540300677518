@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.g-recaptcha {
    margin-bottom: 10px;

    & > div {
        max-width: 100%;
    }
}

.login-form-card {
    max-width: 480px;
}

@include media-breakpoint-up(md) {
    .login-form-container {
        max-width: 480px;
    }
}